import React from "react";
import { Divider, Grid, List, ListItem, ListItemText, Paper, Typography } from "@mui/material";
import { useStyles } from "./styled";
import { Link } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";

import { Box } from "@mui/system";

const EnDropdown = () => {
	const classes = useStyles();

	return (
		<Paper elevation={10}>
			<Box py={2}>
				<Typography variant="h6" color="text.secondary">
					Merhaba Dunya.
				</Typography>
			</Box>
			<Divider className={classes.divider} />
			<Box className={classes.enDropdownWrap}>
				<Grid container>
					<Grid item>
						<List>
							<Link to="/" closemobilemenu="true" className={classes.link}>
								<ListItem>
									<ListItemText>Deutsch</ListItemText>
								</ListItem>
							</Link>
							<ListItem>
								<ListItemText>
									English <CheckIcon color="primary" />
								</ListItemText>
							</ListItem>
							<ListItem>
								<ListItemText>Francis</ListItemText>
							</ListItem>
							<ListItem>
								<ListItemText>Italiano</ListItemText>
							</ListItem>
						</List>
					</Grid>
					<Grid item>
						<List>
							<Link to="/" closemobilemenu="true" className={classes.link}>
								<ListItem>
									<ListItemText>Turkce</ListItemText>
								</ListItem>
							</Link>
							<ListItem>
								<ListItemText>Pyccknn</ListItemText>
							</ListItem>
						</List>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};

export default EnDropdown;
