import { emptySplitApi } from "./base";

export const activitylogsApi = emptySplitApi.injectEndpoints({
	endpoints: (build) => ({
		// getBooking: build.query({
		// 	query: ({ id }) => ({
		// 		url: `/booking/${id}`,
		// 	}),
		// }),
		historyFilter: build.query({
			query: (params) => ({
				url: "/history/filter",
				params,
			}),
		}),
	}),
	overrideExisting: false,
});

export const { useHistoryFilterQuery } = activitylogsApi;
