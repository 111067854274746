import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	branch: {
		branch_name: "",
		branch_id: "",
		branch_address: "",
		phone: "",
		password: "",
		company_id: null,
		owner_id: null,
	},
	logged_in_branch: null,
	get_in_branch: null,
};

export const branchSlice = createSlice({
	name: "branch",
	initialState,
	reducers: {
		updateBranchForm: (state, action) => {
			state.branch[action.payload.field] = action.payload.value;
		},

		updateBranchFormFull: (state, action) => {
			state.branch = action.payload;
		},

		resetBranchForm: (state) => {
			state.branch = initialState;
		},
		setLoggedBranch: (state, action) => {
			state.logged_in_branch = action.payload;
		},
		resetLoggedBranch: (state) => {
			state.logged_in_branch = null;
		},
		setGetBranch: (state, action) => {
			state.get_in_branch = action.payload;
		},

		branchProductIncrement: (state, action) => {
			const index = action.payload;

			const existingProduct = state.get_in_branch.products[index];
			existingProduct = {
				...existingProduct,
				quantity: existingProduct.quantity + 1 || 1,
			};
			state.get_in_branch.products[index] = existingProduct;
			console.log(existingProduct);
		},

		branchProductDecrement: (state, action) => {
			// const { image } = action.payload;
			// const existingProduct = state.products.find(
			// 	(pd) => pd.file_key === image.file_key
			// );
			// if (existingProduct.quantity === 1) {
			// 	state.products = state.products.filter(
			// 		(pd) => pd.file_key !== existingProduct.file_key
			// 	);
			// } else {
			// 	existingProduct.quantity--;
			// }
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	updateBranchForm,
	updateBranchFormFull,
	resetBranchForm,
	setLoggedBranch,
	resetLoggedBranch,
	setGetBranch,
	branchProductIncrement,
	branchProductDecrement,
} = branchSlice.actions;

export default branchSlice.reducer;
