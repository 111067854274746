import { emptySplitApi } from "./base";

export const bookingApi = emptySplitApi.injectEndpoints({
	endpoints: (build) => ({
		filteredBookings: build.query({
			query: (params) => ({
				url: "/booking/filter",
				params,
			}),
		}),
		countBookings: build.query({
			query: (params) => ({
				url: "/booking/count",
				params,
			}),
		}),
		getBooking: build.query({
			query: ({ id }) => ({
				url: `/booking/${id}`,
			}),
		}),
		submitBooking: build.mutation({
			query: (body) => ({
				url: "/booking/",
				method: "POST",
				body,
			}),
		}),
		updateBooking: build.mutation({
			query: ({ id, body }) => ({
				url: `/booking/${id}`,
				method: "PUT",
				body,
			}),
		}),
		updateBookingStatus: build.mutation({
			query: ({ id, body }) => ({
				url: `/booking/${id}/status`,
				method: "PATCH",
				body,
			}),
		}),
		deleteBooking: build.mutation({
			query: (id) => ({
				url: `/booking/${id}`,
				method: "DELETE",
			}),
		}),
		updateBookingOpen: build.mutation({
			query: ({ id, body }) => ({
				url: `/booking/open/${id}`,
				method: "PUT",
				body,
			}),
		}),
		updateBookingStatusOpen: build.mutation({
			query: ({ id, body }) => ({
				url: `/booking/open/${id}/status`,
				method: "PATCH",
				body,
			}),
		}),
		deleteBookingOpen: build.mutation({
			query: (id) => ({
				url: `/booking/open/${id}`,
				method: "DELETE",
			}),
		}),
	}),
	overrideExisting: false,
});

export const {
	useFilteredBookingsQuery,
	useSubmitBookingMutation,
	useGetBookingQuery,
	useCountBookingsQuery,
	useUpdateBookingMutation,
	useUpdateBookingStatusMutation,
	useDeleteBookingMutation,
	useUpdateBookingOpenMutation,
	useUpdateBookingStatusOpenMutation,
	useDeleteBookingOpenMutation,
} = bookingApi;
