import { emptySplitApi } from "./base";

export const bucketApi = emptySplitApi.injectEndpoints({
	tagTypes: ["Post"],
	endpoints: (build) => ({
		createBucket: build.mutation({
			query: (body) => ({
				url: "/bucket/create_bucket",
				method: "POST",
				body,
			}),
		}),
		listBucketObjects: build.query({
			query: (params) => ({
				url: "/bucket/list_objects",
				params,
			}),
		}),
		uploadFile: build.mutation({
			query: (body) => ({
				url: "/bucket/upload_file",
				method: "POST",
				body,
			}),
		}),
		uploadMultipleFile: build.mutation({
			query: (body) => ({
				url: "/bucket/upload_multiple_file",
				method: "POST",
				body,
				headers: {
					"Content-Type": "multipart/form-data",
				},
			}),
		}),

		filterArchive: build.query({
			query: (params) => ({
				url: "/bucket/filtered_archive",
				params,
			}),
			providesTags: ["Post"],
		}),

		deleteArchivePhotos: build.mutation({
			query: (body) => ({
				url: `/bucket/delete_multiple_file`,
				method: "POST",
				body,
			}),
			invalidatesTags: ["Post"],
		}),
	}),
	overrideExisting: false,
});

export const {
	useCreateBucketMutation,
	useListBucketObjectsQuery,
	useUploadFileMutation,
	useUploadMultipleFileMutation,
	useFilterArchiveQuery,
	useDeleteArchivePhotosMutation,
} = bucketApi;
