import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	photopass: "",
	email: "",
	restPhotos: [],
	isTaken: false,
};

export const onlineStoreSlice = createSlice({
	name: "onlineStore",
	initialState,
	reducers: {
		setOnlineStoreForm: (state, action) => {
			state[action.payload.field] = action.payload.value;
		},
		resetOnlineStoreForm: (state) => {
			state.photopass = "";
			state.email = "";
		},
		restPhotosOffer: (state, action) => {
			state.restPhotos = [...action.payload];
		},
		setIsTaken: (state, action) => {
			state.isTaken = action.payload;
		},
	},
});

export const { setOnlineStoreForm, resetOnlineStoreForm, restPhotosOffer, setIsTaken } = onlineStoreSlice.actions;

export default onlineStoreSlice.reducer;
