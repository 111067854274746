import { emptySplitApi } from "./base";

export const branchApi = emptySplitApi.injectEndpoints({
	endpoints: (build) => ({
		filteredBranches: build.query({
			query: (params) => ({
				url: "/branch/filter",
				params,
			}),
		}),
		getAllBranch: build.query({
			query: () => ({
				url: "/branch/",
			}),
		}),
		getBranch: build.query({
			query: ({ id }) => ({
				url: `/branch/${id}`,
			}),
		}),

		submitBranch: build.mutation({
			query: (body) => ({
				url: "/branch/",
				method: "POST",
				body,
			}),
		}),
		updateBranch: build.mutation({
			query: ({ id, body }) => ({
				url: `/branch/${id}`,
				method: "PUT",
				body,
			}),
		}),
		deleteBranch: build.mutation({
			query: (id) => ({
				url: `/branch/${id}`,
				method: "DELETE",
			}),
		}),
		getBranchById: build.mutation({
			query: (id) => ({
				url: `/branch/by_id/${id}`,
			}),
		}),

		submitProduct: build.mutation({
			query: ({ id, body }) => ({
				url: `/branch/add_product/${id}`,
				method: "PUT",
				body,
			}),
		}),
		updateProduct: build.mutation({
			query: ({ id, params, body }) => ({
				url: `/branch/update_product/${id}`,
				method: "PUT",
				params,
				body,
			}),
		}),
		deleteProduct: build.mutation({
			query: ({ id, params }) => ({
				url: `/branch/delete_product/${id}`,
				method: "PUT",
				params,
			}),
		}),
		submitPackage: build.mutation({
			query: ({ id, body }) => ({
				url: `/branch/add_package/${id}`,
				method: "PUT",
				body,
			}),
		}),
		updatePackage: build.mutation({
			query: ({ id, params, body }) => ({
				url: `/branch/update_package/${id}`,
				method: "PUT",
				params,
				body,
			}),
		}),
		deletePackage: build.mutation({
			query: ({ id, params }) => ({
				url: `/branch/delete_package/${id}`,
				method: "PUT",
				params,
			}),
		}),
	}),
	overrideExisting: false,
});

export const {
	useFilteredBranchesQuery,
	useGetAllBranchQuery,
	useGetBranchQuery,
	useSubmitBranchMutation,
	useUpdateBranchMutation,
	useDeleteBranchMutation,
	useGetBranchByIdMutation,
	useSubmitProductMutation,
	useUpdateProductMutation,
	useDeleteProductMutation,
	useSubmitPackageMutation,
	useUpdatePackageMutation,
	useDeletePackageMutation,
} = branchApi;
