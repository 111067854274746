import { Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import "./orderPlace.css";
import { useIdleTimer } from "react-idle-timer";
import { Helmet } from "react-helmet-async";

const OnlineOrderPlace = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const handleOnIdle = (event) => {
		navigate(`/online-store/gallery`);
	};

	useIdleTimer({
		timeout: 1000 * 5,
		onIdle: handleOnIdle,
		debounce: 500,
	});

	return (
		<div className="bg-booking">
			<Helmet>
				<title>Congratulations - Holipic</title>
			</Helmet>
			{/* <ParticlesDiv /> */}
			<Container className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
				<div className="order-place-card text-center py-5">
					<div className="w4rAnimated_checkmark">
						<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
							<circle
								className="path circle"
								fill="none"
								stroke="#1ea5e9"
								strokeWidth="6"
								strokeMiterlimit="10"
								cx="65.1"
								cy="65.1"
								r="62.1"
							/>
							<polyline
								className="path check"
								fill="none"
								stroke="#1ea5e9"
								strokeWidth="6"
								strokeLinecap="round"
								strokeMiterlimit="10"
								points="100.2,40.2 51.5,88.8 29.8,67.5 "
							/>
						</svg>
					</div>
					{/* <i className="far fa-check-circle textBlue tickMark"></i> */}
					{/* <img src={checkMark} alt="" /> */}
					<h2 className="my-4">Thank you for your order!</h2>

					<p>
						We just send you an email with the details of <br /> your private account credentials.
					</p>
					<p>Your order number {location?.state?.orderId}</p>
				</div>
			</Container>
		</div>
	);
};

export default OnlineOrderPlace;
