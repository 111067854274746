import { configureStore } from "@reduxjs/toolkit";
import bookingSlice from "./slices/booking.slice";
import settingsSlice from "./slices/settings.slice";
import authSlice from "./slices/auth.slice";
import companySlice from "./slices/company.slice";
import branchSlice from "./slices/branch.slice";
import searchSlice from "./slices/search.slice";
import notificationSlice from "./slices/notification.slice";
import selectedImageSlice from "./slices/selectedImage.slice";
import packageSlice from "./slices/package.slice";
import contactSlice from "./slices/contact.slice";
import totalSlice from "./slices/total.slice";
import archiveSlice from "./slices/archive.slice";
import onlineStoreSlice from "./slices/onlineStore.slice";
import smtpSlice from "./slices/smtp.slice";
import activitySlice from "./slices/activity.slice";

import { extendedApi } from "./api/test.api";
import { bookingApi } from "./api/booking.api";
import { settingsApi } from "./api/settings.api";
import { companyApi } from "./api/company.api";
import { branchApi } from "./api/branch.api";
import { bucketApi } from "./api/bucket.api";
import { photopassApi } from "./api/photopass.api";
import { smtpApi } from "./api/smtp.api";
import { activitylogsApi } from "./api/activitylogs.api";

export const store = configureStore({
	reducer: {
		settings: settingsSlice,
		booking: bookingSlice,
		auth: authSlice,
		company: companySlice,
		branch: branchSlice,
		search: searchSlice,
		notification: notificationSlice,
		selectedImage: selectedImageSlice,
		packages: packageSlice,
		contact: contactSlice,
		total: totalSlice,
		archive: archiveSlice,
		onlineStore: onlineStoreSlice,
		smtp: smtpSlice,
		activity: activitySlice,

		[extendedApi.reducerPath]: extendedApi.reducer,
		[bookingApi.reducerPath]: bookingApi.reducer,
		[settingsApi.reducerPath]: settingsApi.reducer,
		[companyApi.reducerPath]: companyApi.reducer,
		[branchApi.reducerPath]: branchApi.reducer,
		[bucketApi.reducerPath]: bucketApi.reducer,
		[photopassApi.reducerPath]: photopassApi.reducer,
		[smtpApi.reducerPath]: smtpApi.reducer,
		[activitylogsApi.reducerPath]: activitylogsApi.reducer,
	},

	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});
