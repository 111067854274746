import { emptySplitApi } from "./base";

export const extendedApi = emptySplitApi.injectEndpoints({
	endpoints: (build) => ({
		example: build.query({
			query: () => "https://jsonplaceholder.typicode.com/todos/1",
		}),
	}),
	overrideExisting: false,
});

export const { useExampleQuery } = extendedApi;
