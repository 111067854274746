import { emptySplitApi } from "./base";

export const smtpApi = emptySplitApi.injectEndpoints({
	endpoints: (build) => ({
		getSMTPById: build.query({
			query: ({ branch_id }) => ({
				url: `/smtp/by_id/${branch_id}`,
			}),
		}),
		addSMTP: build.mutation({
			query: (body) => ({
				url: `/smtp`,
				method: "POST",
				body,
			}),
		}),
		updateSMTP: build.mutation({
			query: ({ id, body }) => ({
				url: `/smtp/${id}`,
				method: "PUT",
				body,
			}),
		}),
	}),
	overrideExisting: false,
});

export const { useGetSMTPByIdQuery, useAddSMTPMutation, useUpdateSMTPMutation } = smtpApi;
