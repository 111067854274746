import { emptySplitApi } from "./base";

export const photopassApi = emptySplitApi.injectEndpoints({
	endpoints: (build) => ({
		createPhotopass: build.mutation({
			query: (body) => ({
				url: "/photopass/create_photopass",
				method: "POST",
				body,
			}),
		}),
		getPhotopass: build.query({
			query: (photopass) => ({
				url: `/photopass/${photopass}`,
			}),
		}),
		getRestPhotos: build.query({
			query: (params) => ({
				url: `/bucket/filtered_photos`,
				params,
			}),
		}),
		getOrgByPhotopass: build.query({
			query: (photopass) => ({
				url: `/photopass/org_from_photopass`,
				method: "POST",
				body: `${photopass}`,
			}),
		}),
	}),
	overrideExisting: false,
});

export const { useCreatePhotopassMutation, useGetPhotopassQuery, useGetRestPhotosQuery, useGetOrgByPhotopassQuery } =
	photopassApi;
