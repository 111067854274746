// logo
import NavLogo from "../assets/logo/Logo.png";
import NavLogoWhite from "../assets/logo/Logowhite.png";

import heroImg from "../assets/header.jpeg";
import HeroBennerImg from "../assets/background/hero-banner-bg.png";
import PlayImg from "../assets/play.png";

// carousel
import IphoneXImg from "../assets/carousel/iphone_x.png";
import InStoreImg from "../assets/carousel/In-Store.png";
import PhotoGrapherImg from "../assets/photographer/greenscreenview.png";
// import PhotoGrapherImg from "../assets/photographer/greenscreenview.jpg";

// background
import HolipicAppBg from "../assets/background/download-bg.jpg";

import CCTVImg from "../assets/cctv.PNG";
import WalletImg from "../assets/wallet.PNG";

// benefits
import SettingImg from "../assets/benefits/baseline_settings_black_48dp.png";
import SecurityImg from "../assets/benefits/round_verified_user_black_48dp.png";
import MailImg from "../assets/benefits/round_email_black_48dp.png";
import CloudImg from "../assets/benefits/round_cloud_black_48dp.png";
import AutomationImg from "../assets/benefits/outline_memory_black_48dp.png";
import CameraImg from "../assets/benefits/round_linked_camera_black_48dp.png";

// work
import WorkThumb1 from "../assets/howitworks/work_thumb_1.png";
import WorkThumb2 from "../assets/howitworks/work_thumb_2.png";
import WorkThumb3 from "../assets/howitworks/work_thumb_3.png";

// in-store
import InStoreImg1 from "../assets/in-store/In-Store1.png";
import InStoreImg2 from "../assets/in-store/In-Store2.PNG";
import InStoreImg3 from "../assets/in-store/In-Store3.PNG";

// available in store
import GooglePlay from "../assets/available-in-app/google-play.png";
import Ios from "../assets/available-in-app/ios.PNG";
import Mac from "../assets/available-in-app/mac.PNG";

// refer
import Refer1 from "../assets/refer/refer1.png";
import Refer2 from "../assets/refer/refer2.png";
import Refer3 from "../assets/refer/refer3.png";

// payment
import VisaImg from "../assets/payment/visa.png";
import MaestroImg from "../assets/payment/mestro.png";

// product images
import Product1 from "../assets/products/product1.png";
import Product2 from "../assets/products/product2.png";
import Product3 from "../assets/products/product3.png";

// map
import WorldMapImg from "../assets/map/world-map.png";

// users
import User1 from "../assets/users/User1.jpg";

// in-store selling
import SnapImg from "../assets/instore-selling/SnapIcon.png";
import FaceScannerImg from "../assets/instore-selling/Facescannera.png";
import QRscannerImg from "../assets/instore-selling/qrscan.png";

// demo img
import LoginImgOnlineStore from "../assets/online-store-login-demo.jpg";

const Images = {
	LoginImgOnlineStore,
	// in-store selling
	SnapImg,
	FaceScannerImg,
	QRscannerImg,
	// users
	User1,
	// map
	WorldMapImg,
	// product
	Product1,
	Product2,
	Product3,
	// payment
	VisaImg,
	MaestroImg,
	// refer
	Refer1,
	Refer2,
	Refer3,
	// available in store
	GooglePlay,
	Ios,
	Mac,
	// in store
	InStoreImg1,
	InStoreImg2,
	InStoreImg3,
	// work
	WorkThumb1,
	WorkThumb2,
	WorkThumb3,

	// benefits
	SettingImg,
	SecurityImg,
	MailImg,
	CloudImg,
	AutomationImg,
	CameraImg,

	heroImg,
	PlayImg,
	IphoneXImg,
	InStoreImg,
	HolipicAppBg,
	CCTVImg,
	PhotoGrapherImg,
	WalletImg,
	HeroBennerImg,
	NavLogo,
	NavLogoWhite,
};

export default Images;
