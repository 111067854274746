import { emptySplitApi } from "./base";

export const settingsApi = emptySplitApi.injectEndpoints({
	endpoints: (build) => ({
		getSetting: build.query({
			query: (params) => ({
				url: "/setting/",
				params,
			}),
		}),
		updateSetting: build.mutation({
			query: (body) => ({
				url: "/setting/",
				method: "PUT",
				body,
			}),
		}),

		getCurrencies: build.query({
			query: () => ({
				url: "/setting/currency",
				method: "GET",
			}),
		}),
		submitCurrency: build.mutation({
			query: (body) => ({
				url: "/setting/currency",
				method: "POST",
				body,
			}),
		}),
		updateCurrency: build.mutation({
			query: ({ id, body }) => ({
				url: `/setting/currency/${id}`,
				method: "PUT",
				body,
			}),
		}),

		getPricing: build.query({
			query: () => ({
				url: "/setting/pricing",
				method: "GET",
			}),
		}),
		updatePricing: build.mutation({
			query: (body) => ({
				url: "/setting/pricing",
				method: "PUT",
				body,
			}),
		}),

		getContent: build.query({
			query: () => ({
				url: "/setting/content",
				method: "GET",
			}),
		}),
		updateContent: build.mutation({
			query: ({ id, body }) => ({
				url: `/setting/content/${id}`,
				method: "PUT",
				body,
			}),
		}),
	}),
	overrideExisting: false,
});

export const {
	useGetSettingQuery,
	useUpdateSettingMutation,

	useGetCurrenciesQuery,
	useSubmitCurrencyMutation,
	useUpdateCurrencyMutation,

	useGetPricingQuery,
	useUpdatePricingMutation,

	useGetContentQuery,
	useUpdateContentMutation,
} = settingsApi;
