import { useState, useEffect } from "react";
import { Avatar, Box, Checkbox, FormControlLabel, Grid, IconButton, MenuItem, Typography } from "@mui/material";
import { useStyles } from "./styled";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Images from "../../../../constants/themeData.js";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import LanguageIcon from "@mui/icons-material/Language";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import BusinessIcon from "@mui/icons-material/Business";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { resetAuthForm, updateAuthForm } from "../../../../store/slices/auth.slice";
import { resetCompanyForm, updateCompanyForm } from "../../../../store/slices/company.slice";
import { currencies } from "../../../../utils/company.utils";
import { UserType } from "../../../../utils/auth.utils";
import { useSubmitCompanyWithUserMutation } from "../../../../store/api/company.api";
import { useRequestVerifyMutation } from "../../../../store/api/auth.api";
import { useNavigate } from "react-router-dom";
import { ROUTING_TREE } from "../../../../constants/siteUrls";
import { COUNTRIES } from "../../../../assets/data/country";
import { useSnackbar } from "notistack";

const SignUp = ({ signUpdDialogOpen, handleSignUpDialogClose }) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [countryCode, setCountryCode] = useState("+99");

	const handleMsgVariant = (variant, msg) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(msg + "!", { variant });
	};

	// const user = useSelector((state) => state.auth.user);
	const userState = useSelector((state) => state.auth.user);
	const company = useSelector((state) => state.company.company);

	const [agreed, setAgreed] = useState(true);

	const [submitCompany, { data, error, isLoading }] = useSubmitCompanyWithUserMutation();
	const [requestVerification, { data: dataReq, error: errorReq }] = useRequestVerifyMutation();

	useEffect(() => {
		if (data?.code === 200) {
			handleMsgVariant("success", data?.message);
			setTimeout(() => {
				handleMsgVariant(
					"warning",
					"We have sent an verification link to your mail. Please verify your mail and login to continue!",
				);
			}, 3000);
			dispatch(resetAuthForm());
			dispatch(resetCompanyForm());
			if (userState.email) requestVerification({ email: userState.email });
			navigate(`/${ROUTING_TREE.AUTH.AUTH}/${ROUTING_TREE.AUTH.LOGIN}`);
		}
		if (data?.code === 400) {
			handleMsgVariant("error", data?.message);
		}

		if (error) {
			console.log(error);
			if (error.status === 422) {
				handleMsgVariant("warning", "Please fill up field with valid data !");
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, error]);

	useEffect(() => {
		if (dataReq === null) {
			console.log(dataReq);
		}

		if (errorReq) {
			console.log(errorReq);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataReq, errorReq]);

	useEffect(() => {
		if (signUpdDialogOpen)
			dispatch(
				updateAuthForm({
					field: "user_type",
					value: UserType.COMPANY_ADMIN,
				}),
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [signUpdDialogOpen]);

	const handleSubmit = () => {
		// if () {
		// 	setWarning(true);
		// } else {
		// 	submitCompany();
		// }

		const user = {
			full_name: userState.full_name,
			nick_name: userState.nick_name,
			email: userState.email,
			phone: userState.phone,
			password: userState.password,
			user_type: userState.user_type,
			is_active: userState.is_active,
			is_superuser: userState.is_superuser,
			is_verified: userState.is_verified,
		};

		submitCompany({
			user,
			company,
		});
	};

	// set country mobile code
	useEffect(() => {
		const selectedCountry = COUNTRIES.find((country) => country.name === company.country);
		setCountryCode(selectedCountry?.mobileCode);
	}, [company.country]);

	return (
		<Box component="div">
			<Dialog
				className={classes.dialogRoot}
				open={signUpdDialogOpen}
				onClose={handleSignUpDialogClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					<IconButton
						onClick={handleSignUpDialogClose}
						sx={{
							position: "absolute",
							right: 15,
							top: 8,
						}}
						aria-label="upload picture"
						component="span"
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent
					sx={{
						"&::-webkit-scrollbar": {
							width: 8,
						},
						"&::-webkit-scrollbar-track": {
							background: "#fff",
						},
						"&::-webkit-scrollbar-thumb": {
							background: "#e2e2e2",
							borderRadius: 5,
						},
					}}
				>
					<Grid container spacing={5} alignItems="center">
						<Grid item xs={12} md={6}>
							<Typography color="text.primary" variant="h6" sx={{ mb: 3, fontWeight: "regular" }}>
								Lets's get started, <br />
								Sign Up just in minutes{" "}
							</Typography>
							{/* form */}
							<Box component="form" autoComplete="off" mt={2}>
								<Box my={2}>
									<TextField
										className={classes.textField}
										fullWidth
										id="input-with-icon-textfield"
										label="Enter Your Full Name"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<AccountCircleIcon fontSize="small" />
												</InputAdornment>
											),
										}}
										variant="outlined"
										value={userState.full_name}
										onChange={(e) =>
											dispatch(
												updateAuthForm({
													field: "full_name",
													value: e.target.value,
												}),
											)
										}
									/>
								</Box>
								<Box my={2}>
									<TextField
										className={classes.textField}
										fullWidth
										id="input-with-icon-textfield"
										label="Enter Company Name"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<BusinessIcon fontSize="small" />
												</InputAdornment>
											),
										}}
										variant="outlined"
										value={company.company_name}
										onChange={(e) =>
											dispatch(
												updateCompanyForm({
													field: "company_name",
													value: e.target.value,
												}),
											)
										}
									/>
								</Box>
								<Box my={2}>
									<TextField
										className={classes.textField}
										fullWidth
										id="input-with-icon-textfield"
										label="Enter Company Address"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<BusinessIcon fontSize="small" />
												</InputAdornment>
											),
										}}
										variant="outlined"
										value={company.company_address}
										onChange={(e) =>
											dispatch(
												updateCompanyForm({
													field: "company_address",
													value: e.target.value,
												}),
											)
										}
									/>
								</Box>
								<Box my={2}>
									<TextField
										className={classes.textField}
										fullWidth
										label="Select Country"
										id="outlined-select-currency"
										select
										value={company.country}
										onChange={(e) =>
											dispatch(
												updateCompanyForm({
													field: "country",
													value: e.target.value,
												}),
											)
										}
										variant="outlined"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<LanguageIcon fontSize="small" />
												</InputAdornment>
											),
										}}
									>
										{COUNTRIES.map((option) => (
											<MenuItem key={option.name} value={option.name}>
												{option.name}
											</MenuItem>
										))}
									</TextField>
								</Box>
								<Box my={2}>
									<TextField
										className={classes.textField}
										fullWidth
										id="input-with-icon-textfield"
										label="Enter Your Email"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<EmailIcon fontSize="small" />
												</InputAdornment>
											),
										}}
										variant="outlined"
										value={userState.email}
										onChange={(e) =>
											dispatch(
												updateAuthForm({
													field: "email",
													value: e.target.value,
												}),
											)
										}
									/>
								</Box>
								<Box my={2}>
									<Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
										<TextField
											type="tel"
											className={classes.textField}
											fullWidth
											id="input-with-icon-textfield"
											label="Enter Phone Number"
											autoComplete="new-phone"
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<LocalPhoneIcon fontSize="small" /> {countryCode}
													</InputAdornment>
												),
											}}
											variant="outlined"
											value={userState.phone}
											onChange={(e) =>
												dispatch(
													updateAuthForm({
														field: "phone",
														value: e.target.value,
													}),
												)
											}
										/>

										{/* <Box
											sx={{
												fontWeight: 'medium',
												bgcolor: '#f2f2f2',
												color: '#999999',
												padding: '7px',
												borderRadius: 1,
											}}
										>
											+88
										</Box> */}
									</Box>
								</Box>
								<Box my={2}>
									<TextField
										type="password"
										className={classes.textField}
										fullWidth
										id="input-with-icon-textfield"
										label="Enter Your Password"
										autoComplete="new-password"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<LockIcon fontSize="small" />
												</InputAdornment>
											),
										}}
										variant="outlined"
										value={userState.password}
										onChange={(e) =>
											dispatch(
												updateAuthForm({
													field: "password",
													value: e.target.value,
												}),
											)
										}
									/>
								</Box>
								<Box my={2}>
									<TextField
										className={classes.textField}
										fullWidth
										label="Select Your Currency"
										id="outlined-select-package"
										select
										value={company.currency}
										onChange={(e) =>
											dispatch(
												updateCompanyForm({
													field: "currency",
													value: e.target.value,
												}),
											)
										}
										variant="outlined"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<CurrencyExchangeIcon fontSize="small" />
												</InputAdornment>
											),
										}}
									>
										{currencies.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</TextField>
								</Box>
								<Box my={0}>
									<FormControlLabel
										control={<Checkbox defaultChecked onChange={() => setAgreed(!agreed)} />}
										label="I read and agree to Terms conditions"
									/>
								</Box>
								<Box
									my={2}
									sx={{
										display: "flex",
										gap: 1,
										flexWrap: "wrap",
									}}
								>
									<Box>
										<Button
											variant="outlined"
											size="small"
											sx={{
												borderRadius: 0,
												textTransform: "capitalize",
											}}
											disabled={!agreed || isLoading}
											onClick={handleSubmit}
										>
											Sign Up
										</Button>
									</Box>
									<Box>
										have an account?{" "}
										<Button
											size="small"
											sx={{ textTransform: "capitalize" }}
											onClick={() =>
												navigate(`/${ROUTING_TREE.AUTH.AUTH}/${ROUTING_TREE.AUTH.LOGIN}`)
											}
										>
											Sign In
										</Button>
									</Box>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} md={6} sx={{ display: { xs: "none", md: "block" } }}>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									gap: 1,
								}}
							>
								<Avatar className={classes.avatarLogo} src={Images.NavLogo} alt="logo" />
								<Typography color="text.primary" variant="h4" sx={{ fontWeight: "medium" }}>
									Holipic App
								</Typography>
							</Box>
							<Typography color="text.light" variant="body2" sx={{ textAlign: "center" }}>
								Start to fully manage your incomes, customers, staff and improves sales by registering
								your company.
							</Typography>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		</Box>
	);
};

export default SignUp;
