import { List, ListItem, ListItemText, Paper } from "@mui/material";
import React from "react";
import { Link as NavHashLink } from "react-scroll";

const AppsDropdown = () => {
	return (
		<Paper elevation={10}>
			<List>
				<ListItem>
					<NavHashLink to="cameraApp" activeClassName="selected" offset={-50}>
						<ListItemText>Camera App</ListItemText>
					</NavHashLink>
				</ListItem>

				<ListItem>
					<NavHashLink to="instoreApp" activeClassName="selected" offset={-50}>
						<ListItemText>In-Store App</ListItemText>
					</NavHashLink>
				</ListItem>
			</List>
		</Paper>
	);
};

export default AppsDropdown;
